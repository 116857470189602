import React, { Component } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  Flex,
  Box,
  Row,
  Banner,
  Heading,
  Divider,
  Subhead,
  Container,
  Button,
  Close,
} from 'rebass';
import Text from '../components/Text';
import { plural } from '../utils/helpers';
import Icon from '../components/Icon';
import PostBanner from '../components/PostBanner';
import Modal from 'react-modal';
import BodyText from '../components/BodyText';
import Page from '../components/Page';
import { graphql, navigateTo } from 'gatsby';
import { navigate } from '@reach/router';
import Helmet from 'react-helmet';

const ModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(218, 218, 220, .75)',
    overflowY: 'auto',
  },
  content: {
    position: 'absolute',
    fontFamily: 'sans-serif',
    top: '80px',
    left: '10%',
    right: '10%',
    bottom: 'auto',
    border: '0px',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    maxWidth: '1024px',
    marginRight: 'auto',
    marginLeft: 'auto',
    scrollBehavior: 'smooth',
  },
};

const IconText = styled(Text)`
  position: absolute;
`;

const PostDivider = styled(Divider)`
  color: gray3;
`;
const PostContainer = styled(Container)`
  margin: 3rem auto;
  max-width: 600px;
`;

const HeadImage = styled(Img)`
  max-height: 60vh;
`;

const BackButton = styled(Close)`
  position: absolute;
  right: 31px;
  width: 38px;
  height: 50px;
  font-size: 30px;
  color: white;

  :hover {
    box-shadow: none;
    color: #dee1e5;
    cursor: pointer;
  }
`;

export default class PostPage extends Component {
  back = e => {
    // stop going back if there is no history
    e.stopPropagation();
    if (window.history.length > 2) {
      window.history.back();
    } else navigateTo('/');
  };

  // componentDidMount() {
  //   fetchItem();
  // }

  render() {
    let stateFeed;
    if (this.props.location.state == null) {
      stateFeed = false;
    } else {
      stateFeed = true;
    }
    const { data } = this.props;
    const {
      title,
      featuredImage,
      body,
      hearts,
      slug,
      preview,
      date,
      status,
    } = this.props.data.contentfulArticles;
    const readTime = body.childMarkdownRemark.timeToRead;
    if (!data) return null;

    // get image SRC and turn into a URL for Twitter image
    // images.ctfassets.net/6ayje5b83sba/duRs266yCkyiys4iWaayi/1e8077007ad7547b3f2204c1326372ae/harder.jpg
    // remove before and stop at ?
    const rawURL = featuredImage.sizes.src.substring(2);
    const splitURL = rawURL.split('?');
    const firstURL = splitURL[0];

    let bbutton;
    if (stateFeed == true && window.innerWidth > 550) {
      bbutton = <BackButton onClick={this.back} />;
    } else {
      bbutton;
    }
    if (stateFeed == true && window.innerWidth > 550) {
      return (
        <Page>
          <Helmet>
            <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
            <meta name="description" content={preview} />
            <meta name="image" content={`https://${firstURL}`} />
            <meta
              property="og:title"
              content={`${title} | ${data.site.siteMetadata.title}`}
            />
            <meta property="og:image" content={`https://${firstURL}`} />
            <meta property="og:description" content={preview} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@BrentSum" />
            <meta
              name="twitter:title"
              content={`${title} | ${data.site.siteMetadata.title}`}
            />
            <meta name="twitter:description" content={preview} />
            <meta name="twitter:image" content={`https://${firstURL}`} />
          </Helmet>
          <Modal
            isOpen={Boolean(stateFeed)}
            onRequestClose={this.back}
            style={ModalStyle}
          >
            {bbutton}
            <PostBanner
              readTime={readTime}
              hearts={hearts}
              title={title}
              type="article"
              slug={slug}
              date={date}
              status={status}
            />
            <PostContainer>
              <br />
              <BodyText
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              />
            </PostContainer>
          </Modal>
        </Page>
      );
    }
    return (
      <div>
        {bbutton}
        <Page>
          <Helmet>
            <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
            <meta name="description" content={preview} />
            <meta name="image" content={`https://${firstURL}`} />
            <meta
              property="og:title"
              content={`${title} | ${data.site.siteMetadata.title}`}
            />
            <meta property="og:image" content={`https://${firstURL}`} />
            <meta property="og:description" content={preview} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@BrentSum" />
            <meta
              name="twitter:title"
              content={`${title} | ${data.site.siteMetadata.title}`}
            />
            <meta name="twitter:description" content={preview} />
            <meta name="twitter:image" content={`https://${firstURL}`} />
          </Helmet>
          <PostBanner
            readTime={readTime}
            hearts={hearts}
            title={title}
            type="article"
            slug={slug}
            date={date}
            status={status}
          />
          <PostContainer>
            <br />
            <BodyText
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
          </PostContainer>
        </Page>
      </div>
    );
  }
}

export const query = graphql`
  query ArticleQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulArticles(slug: { eq: $slug }) {
      title
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      slug
      hearts
      preview
      id
      status
      date
      featuredImage {
        sizes(maxWidth: 800) {
          ...GatsbyContentfulSizes
        }
      }
    }
  }
`;
